const setFloodModalStatus = (status) => {
  return {
    type: 'SET_FLOOD_MODAL_STATUS',
    payload: status,
  }
}

const setShowBuildingDamages = (bool) => {
  return {
    type: 'SET_SHOW_BUILDING_DAMAGES',
    payload: bool,
  };
};

const setShowDamageArea = (status) => {
  return {
    type: 'SET_SHOW_DAMAGE_AREA',
    payload: status,
  };
};

const setCurrentSelectedDamageLayer = (damageLayer) => {
  return {
    type: 'SET_CURRENT_SELECTED_DAMAGE_LAYER',
    payload: damageLayer,
  }
}

const setShowDeleteAndEditButtons = (status) => {
  return {
    type: 'SET_SHOW_DELETE_AND_EDIT_BUTTONS',
    payload: status,
  }
}

function setSelectedAreaId(id) {
  return {
    type: 'SET_SELECTED_AREA_ID',
    payload: id,
  };
}

function setUACOpacity(level) {
  return {
    type: 'SET_UAC_OPACITY',
    payload: level,
  };
}

export {
  setCurrentSelectedDamageLayer,
  setFloodModalStatus,
  setSelectedAreaId,
  setShowBuildingDamages,
  setShowDamageArea,
  setShowDeleteAndEditButtons,
  setUACOpacity,
}