import axios from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';

import queryClient from '@utils/queries/QueryClient';
import { useAuth } from '@App/components/AuthProvider/auth-provider.context';

function useUserDetails(enabled = true) {
  return useQuery({
    queryKey: ['user', 'user-details'],
    queryFn: async () => {
      const { data } = await axios.get('/api/user');
      return data;
    },
    enabled,
  });
}

function useUserSettings() {
  const { isLoggedIn } = useAuth();

  return useQuery({
    queryKey: ['user', 'user-settings'],
    queryFn: async () => {
      const { data } = await axios.get('/api/user/settings');
      return data;
    },
    enabled: isLoggedIn,
  });
}

function useUpdateUserSettingsMutation() {
  return useMutation({
    mutationFn: updatedSettings => axios.post('/api/user/settings', { updatedSettings }),
    // Optimistic update: https://tanstack.com/query/v5/docs/framework/react/guides/optimistic-updates
    onMutate: async (update) => {
      await queryClient.cancelQueries({ queryKey: ['user', 'user-settings'] });
      const previousUserSettings = queryClient.getQueryData(['user', 'user-settings']);

      queryClient.setQueryData(['user', 'user-settings'], old => ({
        ...old,
        ...update,
      }));

      return { previousUserSettings };
    },
    onError: (_err, _data, context) => {
      queryClient.setQueryData(['user', 'user-settings'], context.previousUserSettings);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['user', 'user-settings'] });
    },
  });
}

function useUserAreas() {
  const { isLoggedIn } = useAuth();

  return useQuery({
    queryKey: ['user', 'areas'],
    queryFn: async () => {
      const { data } = await axios.get('/api/user/areas');
      return data;
    },
    enabled: isLoggedIn,
  });
}

function useUserDamageParameters() {
  const { isLoggedIn } = useAuth();

  return useQuery({
    queryKey: ['user', 'damage-parameters'],
    queryFn: async () => {
      const { data } = await axios.get('/api/damage-parameters');
      return data;
    },
    enabled: isLoggedIn,
  });
}
function useSpecificUserDamageParameter(id) {
  const { isLoggedIn } = useAuth();

  return useQuery({
    queryKey: ['user', 'damage-parameters', id],
    queryFn: async () => {
      const { data } = await axios.get(`/api/damage-parameters/${id}`);
      return data;
    },
    enabled: isLoggedIn,
  });
}

export {
  useUserAreas,
  useUserDetails,
  useUserSettings,
  useUpdateUserSettingsMutation,
  useSpecificUserDamageParameter,
  useUserDamageParameters,
};