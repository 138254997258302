import { useSelector } from 'react-redux';
import { Toggle } from '@nazka/nazka.mapframe.components';

import UrbanAtlasCoverLegend from '@App/components/UrbanAtlasCoverLegend/UrbanAtlasCoverLegend';
import UrbanAtlasWMSLayer from '@App/components/UrbanAtlasWMSLayer/UrbanAtlasWMSLayer';
import { useUserSettings, useUpdateUserSettingsMutation } from '@utils/queries/user.queries';
import Slider from '@library/Slider/Slider';

import './urban-atlas-cover.styl';

function UrbanAtlasCover() {
  const { mutate: updateUserSettings } = useUpdateUserSettingsMutation();
  const { data: userSettings } = useUserSettings();

  const map = useSelector(state => state.maplibreMap.map);

  const { activeLandUseFeature } = userSettings || {};

  return (
    <div className="urban-atlas-cover">
      <div className="urban-atlas-cover__wrapper">
        <Toggle
          label="Urban Atlas Cover"
          name="UAC"
          checked={activeLandUseFeature === 'Urban Atlas Cover'}
          activeColor="#0367A5"
          onInputChange={() => updateUserSettings({
            activeLandUseFeature: `${activeLandUseFeature === 'Urban Atlas Cover' ? null : 'Urban Atlas Cover'}`,
          })}
          css={{
            fontSize: '14px',
            input: { fontSize: '12px' },
            marginBottom: '6px',
          }}
        />
        {activeLandUseFeature === 'Urban Atlas Cover' && (
          <UrbanAtlasCoverLegend />
        )}
      </div>
      {activeLandUseFeature === 'Urban Atlas Cover' && (
      <div className="urban-atlas-cover__opacity__wrapper">
        <span>Opacity:</span>
        <Slider />
      </div>
      )}
    </div>
  )
}

export default UrbanAtlasCover