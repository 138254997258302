/* eslint-disable max-lines */
import { useState, useEffect } from 'react'
import { Button, ScrollContainer } from '@nazka/nazka.mapframe.components'
import {
  faXmark, faSliders, faPlus, faTrash,
  faPencil, 
} from '@fortawesome/pro-solid-svg-icons'
import { useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import axios from 'axios'
import queryClient from '@utils/queries/QueryClient';

import { useUserDamageParameters } from '@utils/queries/user.queries'
import { useSelector, useDispatch } from 'react-redux'
import DamageParameterTable from './DamageParameterTable/DamageParameterTable'
import { setShowDamagePrameterTable } from './damage-parameter.redux.actions'

import './damage-parameter.styl'

function DamageParameter({ onExpandChange }) {
  const [isExpanded, setIsExpanded] = useState(false)
  const [deleteIsTriggered, setDeleteIsTriggered] = useState(null)
  const [specificDamageParameterId, setSpecificDamageParameterId] = useState(null)
  const dispatch = useDispatch()
  const showDamagePrameterTable = useSelector(state => state.damageParameter.showDamagePrameterTable)
  const showData = useSelector(state => state.t1.showData);

  const { data: userDamageParameters } = useUserDamageParameters()

  const { mutate: removeDamageParameter } = useMutation({
    mutationFn: async (damageParameterId) => {
      await axios.delete(`/api/damage-parameters/${damageParameterId}`);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['user', 'damage-parameters'] })
    },
    onSuccess: () => {
      toast.success('Successfully deleted your damage parameter')
      setDeleteIsTriggered(false)
    },
    onError: error => toast.error(error.message || 'Something went wrong deleting your damage parameter'),
  });

  useEffect(() => {
    if (showData) {
      setIsExpanded(false)
    }
  }, [showData])

  return (
    <div className="damage-paremeter">
      {isExpanded && (
        <ScrollContainer
          bodyElProps={{
            style: {
              minHeight: '114px',
              backgroundColor: 'white',
              borderRadius: '4px',
            },
          }}
          maxHeight={114}
          css={{
            width: '275px',
          }}
        >
          <div className="damage-parameter__card">
            <div className="damage-parameter__card-header">
              <span> Damage paremeters</span>
              <Button
                className="damage-parameter__card__add__button"
                icon={faPlus}
                layout="ghost"
                iconSize="15px"
                color="grey"
                hover={false}
                onClick={() => dispatch(setShowDamagePrameterTable(true))}
                css={{
                  padding: 0,
                }}
              />
            </div>
            <hr style={{
              opacity: 0.1, margin: '8px 10px 010px',
            }}
            />
            <div className="damage-parameter__saved-params__wrapper">
              {userDamageParameters?.length > 0 ? (
                <div>
                  {userDamageParameters.map((damage) => {
                    return (
                      <div className="damage-parameter__saved-params">
                        <span>{damage.parameterName}</span>
                        {damage.id === deleteIsTriggered ? (
                          <div className="damage-parameter__confirmation__wrapper">
                            <Button
                              color="#0367A5"
                              hoverColor="#0367A5"
                              css={{
                                borderRadius: '3px',
                                padding: '3px',
                                fontSize: '12px',
                                marginBottom: '6px',
                              }}
                              onClick={() => removeDamageParameter(damage.id)}
                            >
                              Confirm
                            </Button>
                            <Button
                              color="#0367A5"
                              hoverColor="#0367A5"
                              onClick={() => setDeleteIsTriggered(null)}
                              layout="outlined"
                              css={{
                                borderRadius: '3px',
                                padding: '3px',
                                fontSize: '12px',
                                marginBottom: '6px',
                              }}
                            >
                              Cancel
                            </Button>
                          </div>
                        ) : (
                          <div className="damage-parameter__saved-params__button__wrapper">
                            <Button 
                              icon={faPencil} 
                              onClick={() => {
                                setSpecificDamageParameterId(damage.id)
                                dispatch(setShowDamagePrameterTable(true))
                              }} 
                              color="white"
                              hover={false}
                              className="damage-parameter__edit__button"
                              iconProps={{
                                size: '14px',
                              }}
                              css={{
                                color: 'grey',
                                marginLeft: 0,
                                padding: 0,
                                '&:hover': {
                                  color: 'black',
                                },
                                '&:disabled': {
                                  backgroundColor: 'transparent',
                                  border: 'none',
                                  color: 'grey',
                                  '&:hover': {
                                    color: 'grey',
                                  },
                                },
                              }}
                            />
                            <Button 
                              icon={faTrash} 
                              onClick={() => setDeleteIsTriggered(damage.id)} 
                              color="white"
                              hover={false}
                              className="damage-parameter__delete__button"
                              iconProps={{
                                size: '14px',
                              }}
                              css={{
                                color: 'grey',
                                marginLeft: 0,
                                padding: 0,
                                '&:hover': {
                                  color: 'black',
                                },
                                '&:disabled': {
                                  backgroundColor: 'transparent',
                                  border: 'none',
                                  color: 'grey',
                                  '&:hover': {
                                    color: 'grey',
                                  },
                                },
                              }}
                            />
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
                
              ) : (
                <span style={{
                  fontSize: '13px', fontStyle: 'italic', display: 'flex', justifyContent: 'center',
                }}
                >
                  Click on + to create a damage parameter
                </span>
              )}
            </div>
          
          </div>
        </ScrollContainer>
      )}
      <div>
        <Button
          icon={isExpanded ? faXmark : faSliders}
          color="white"
          css={{
            borderRadius: '3px',
          }}
          onClick={() => {
            setIsExpanded((prev) => {
              const newState = !prev;
              if (onExpandChange) onExpandChange(newState); // Notify parent of state change
              return newState;
            });
          }}
        />
      </div>
      {showDamagePrameterTable && (
        <DamageParameterTable
          specificDamageParameterId={specificDamageParameterId}
          close={() => setSpecificDamageParameterId(null)}
        />
      )}
     
    </div>
  )
}

export default DamageParameter