import { useDispatch, useSelector } from 'react-redux';
import './slider.styl';
import { setUACOpacity } from '@App/components/LayerSelector/layer-selector.redux.action';

function Slider({ className }) {
  const dispatch = useDispatch()
  const value = useSelector(state => state.layerSelector.uacOpacityLevel);

  return (
    <div>
      <input
        type="range"
        min="0"
        max="1"
        step="0.02"
        value={value}
        onChange={e => dispatch(setUACOpacity(Number(e.target.value)))}
        className={className}
        style={{
          border: '1px solid #e2e8f0',
          background: `linear-gradient(to right, #b1d2e7 0%, #b1d2e7 ${value * 100}%, white ${value * 100}%, white 100%)`,
        }}
      />
    </div>
  )
}

export default Slider