const defaultState = {
  isFloodModalOpen: false,
  showBuildingDamages: false,
  currentSelectedDamageLayer: null,
  showDamageArea: false,
  showDeleteAndEditButtons: false,
  selectedAreaId: null,
  uacOpacityLevel: 0.5,
}

const layerSelector = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_FLOOD_MODAL_STATUS':
      return {
        ...state,
        isFloodModalOpen: action.payload,
      }
    case 'SET_SHOW_BUILDING_DAMAGES':
      return {
        ...state,
        showBuildingDamages: action.payload,
      };
    case 'SET_SHOW_DAMAGE_AREA':
      return {
        ...state,
        showDamageArea: action.payload,
      };
    case 'SET_CURRENT_SELECTED_DAMAGE_LAYER':
      return {
        ...state,
        currentSelectedDamageLayer: action.payload,
      }
    case 'SET_SHOW_DELETE_AND_EDIT_BUTTONS':
      return {
        ...state,
        showDeleteAndEditButtons: action.payload,
      }
    case 'SET_SELECTED_AREA_ID':
      return {
        ...state,
        selectedAreaId: action.payload,
      };
    case 'SET_UAC_OPACITY':
      return {
        ...state,
        uacOpacityLevel: action.payload,
      };
    default:
      return state
  }
}

export default layerSelector