import { useSelector, useDispatch } from 'react-redux';
import { useState, useRef, useEffect } from 'react';
import { Button } from '@nazka/nazka.mapframe.components';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import gsap from 'gsap';

import MaplibreMap from '@library/MaplibreMap/MaplibreMap'
import LayerSelector from '@App/components/LayerSelector/LayerSelector';
import LocationSearch from '@App/components/LocationSearch/LocationSearch';
import BackgroundLayerSelector from '@App/components/BackgroundLayerSelector/BackgroundLayerSelector';
import SidePanel from '@App/components/SidePanel/SidePanel';
import CompareModeMap from '@App/components/CompareModeMap/CompareModeMap';
import DamageParameter from '@App/components/DamageParameter/DamageParameter';
import FloodsLayer from '@App/components/FloodsLayer/FloodsLayer';
import { useAuth } from '@App/components/AuthProvider/auth-provider.context';
import { setShowData } from '@templates/T1/t1.redux.actions';
import { usePrev } from '@services/hooks.service';
import { useUserSettings } from '@utils/queries/user.queries';

import './interactive-map.styl';

function InteractiveMap() {
  const { isLoggedIn } = useAuth();
  const dispatch = useDispatch();

  const [, setIsMapLoading] = useState(true);
  const [mapResizeId] = useState();

  const map = useSelector(state => state.maplibreMap.map);
  const showData = useSelector(state => state.t1.showData);
  const isCompareModeOn = useSelector(state => state.buildingDamages.isComparisonModeOn)
  const showBuildingDamages = useSelector(state => state.layerSelector.showBuildingDamages);
  const currentDamage = useSelector(state => state.layerSelector.currentSelectedDamageLayer);
  const isCreatingNewDamageLayer = useSelector(state => state.newDamageLayer.isCreatingNewDamageLayer);

  const datapanelToggleWrapper = useRef()
  const previousCurrentDamage = usePrev(currentDamage);

  const { data: userSettings } = useUserSettings();

  const {
    floodMeasures,
    floodYear,
    isFloodLayerActive,
  } = userSettings || {};

  const scenario = `${floodYear}${floodMeasures || ''}`;

  useEffect(() => {
    if (!showData && previousCurrentDamage !== currentDamage) {
      setTimeout(() => {
        gsap.to(datapanelToggleWrapper.current, {
          keyframes: {
            '0%': { y: 0 },
            '75%': { y: -10 },
            '100%': { y: 0 },
          },
          ease: 'none',
          duration: 0.5,
          repeat: 2,
        })
      }, 1500)
    }
  }, [currentDamage, previousCurrentDamage, showData])

  const damageWrapperRef = useRef(null);
  const layerWrapperRef = useRef(null);
  const [isDamageExpanded, setIsDamageExpanded] = useState(false);
  useEffect(() => {
    if (isDamageExpanded && damageWrapperRef.current && layerWrapperRef.current) {
      const damageHeight = damageWrapperRef.current.offsetHeight;
      const damageTop = parseInt(getComputedStyle(damageWrapperRef.current).top, 10); 
      const newTop = damageHeight + damageTop + 8;

      layerWrapperRef.current.style.top = `${newTop}px`;
    } else if (layerWrapperRef.current) {
      layerWrapperRef.current.style.top = '';
    }
  }, [isDamageExpanded]);

  useEffect(() => {
    if (showData) {
      setIsDamageExpanded(false)
    }
  }, [showData])

  return (
    <div className={`interactive-map ${showData ? 'interactive-map--has-data' : ''}`}>
      {isCreatingNewDamageLayer && (
        <SidePanel />
      )}
      <div className="interactive-map__map">
        {!isCompareModeOn
          && (
            <div className="interactive-map__backgound__layer__selector__wrapper">
              <BackgroundLayerSelector />
            </div>
          )}
        <div className="interactive-map__search-damage-param__wrapper">
          <div className="interactive-map__search__wrapper">
            <LocationSearch />
          </div>
          <div
            className="interactive-map__damage-parameter__wrapper"
            ref={damageWrapperRef}
          >
            <DamageParameter onExpandChange={setIsDamageExpanded} />
          </div>
        </div>
        {isLoggedIn && !isCreatingNewDamageLayer && (
          <div
            className="interactive-map__layer_wrapper"
            ref={layerWrapperRef}
          >
            <LayerSelector />
          </div>
        )}
        {isCompareModeOn ? (
          <CompareModeMap />
        ) : (
          <MaplibreMap
            mapResizeId={mapResizeId}
            onMapInitialized={() => setIsMapLoading(false)}
          />
        )}
      </div>
      {(showBuildingDamages && !isCreatingNewDamageLayer)
        && (
          <div
            className="interactive-map__datapanel__toggle"
            ref={datapanelToggleWrapper}
          >
            <Button
              className="datapanel__toggle"
              icon={showData ? faChevronDown : faChevronUp}
              onClick={() => dispatch(setShowData(!showData))}
              themeColor="primary"
              css={{
                padding: '10px 30px',
                borderRadius: '3px',
                boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
              }}
            />
          </div>
        )}
      {map && (isFloodLayerActive || isCreatingNewDamageLayer) && (
        <FloodsLayer map={map} scenario={scenario} />
      )}
    </div>
  )
}

export default InteractiveMap;